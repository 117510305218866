var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Adicionar aula ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit()},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Título","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"description","state":errors.length > 0 ? false : null}},[_c('b-form-textarea',{attrs:{"id":"textarea-state","state":errors.length > 0 ? false : null,"placeholder":"Descrição...","rows":"3"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Como deseja inserir a aula?"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"label-field":"label","options":_vm.optionsLesson,"reduce":function (option) { return option.value; }},model:{value:(_vm.optionLesson),callback:function ($$v) {_vm.optionLesson=$$v},expression:"optionLesson"}})],1),(_vm.optionLesson === 'upload')?_c('validation-provider',{attrs:{"name":"link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Selecionar vídeo","label-for":"selecionar vídeo","state":errors.length > 0 ? false : null}},[_c('b-form-file',{attrs:{"id":"upload","state":errors.length > 0 ? false : null},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(_vm.optionLesson === 'link')?_c('validation-provider',{attrs:{"name":"link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Link","label-for":"link","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"link","state":errors.length > 0 ? false : null},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('div',{staticClass:"d-flex aling-items-center mt-2"},[(_vm.optionLesson !== 'record')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2 d-flex align-items-center",attrs:{"variant":"primary","type":"submit","disabled":_vm.isBusy}},[(_vm.isBusy)?_c('b-spinner',{staticStyle:{"width":"25px","height":"25px","margin-right":"8px"}}):_vm._e(),(!_vm.isBusy)?_c('span',{staticStyle:{"flex":"1"}},[_vm._v("Adicionar")]):_vm._e()],1):_vm._e(),(_vm.optionLesson === 'record')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"success","type":"submit","disabled":_vm.isBusy}},[(_vm.isBusy)?_c('b-spinner',{staticStyle:{"width":"25px","height":"25px","margin-right":"8px"}}):_vm._e(),(!_vm.isBusy)?_c('feather-icon',{staticClass:"mr-0",attrs:{"icon":"VideoIcon"}}):_vm._e(),(!_vm.isBusy)?_c('span',[_vm._v("Gravar aula")]):_vm._e()],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancelar ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }